import axios from "axios";
import { Attempt, HomeworkAttempt, UserAnswer } from "../../types/attempt";
import { APIPlatformList } from "../../types/utils";

const attemptService = {
  getAttempts: async (
    page = 1,
    limit = 30,
    examType?: string | string[],
    examName?: string,
    userId?: string,
    correctorId?: string | null,
    filterWithoutCorrector?: boolean,
    correctionStatus?: string[],
    sentAtOrder?: "asc" | "desc" | null,
    correctedAtOrder?: "asc" | "desc" | null,
    startAt?: string | null,
    endAt?: string | null
  ): Promise<{
    attempts: Attempt[];
    totalItems: number;
    totalPages: number;
  }> => {
    const params: any = {
      page,
      limit,
      "exam.type": examType,
      "exam.name": examName,
      "enrolment.user": userId,
      correctionStatus,
      ...(sentAtOrder && { "order[sentAt]": sentAtOrder }),
      ...(correctedAtOrder && { "order[correctedAt]": correctedAtOrder }),
      ...(filterWithoutCorrector && { "exists[corrector]": false }),
      ...(correctorId && { corrector: correctorId }),
      "correctedAt[after]": startAt,
      "correctedAt[before]": endAt,
    };

    const {
      data: { "hydra:member": attempts, "hydra:totalItems": totalItems },
    } = await axios.get<APIPlatformList<Attempt>>("/admin/exam_attempts", {
      params,
    });

    let totalUnits = Math.floor(totalItems / limit);

    if (totalItems % limit > 0) {
      totalUnits += 1;
    }

    const totalPages = totalUnits === 0 ? 1 : totalUnits;

    return { attempts, totalItems, totalPages };
  },
  getAttempt: async (attemptId: string): Promise<Attempt> => {
    const { data: attempt } = await axios.get<Attempt>(`/admin/attempts/${attemptId}`);

    return attempt;
  },
  getTestAttempt: async (attemptId: string): Promise<Attempt> => {
    const { data: attempt } = await axios.get<Attempt>(`/admin/test_attempts/${attemptId}`);

    return attempt;
  },
  getHomeworkAttempt: async (attemptId: string): Promise<Attempt> => {
    const { data: attempt } = await axios.get<Attempt>(`/admin/homework_attempts/${attemptId}`);

    return attempt;
  },
  saveProgress: async (attemptId: string, rating?: number | null, assessment?: string) => {
    const { data: attempt } = await axios.put<Attempt | HomeworkAttempt>(`admin/attempts/${attemptId}`, { rating, assessment }, {});

    return attempt;
  },
  saveHomeworkCorrectionProgress: async (attemptId: string, rating?: number | null, assessment?: string, correctionDocumentIri?: string | null) => {
    const { data: attempt } = await axios.put<HomeworkAttempt>(`admin/attempts/${attemptId}`, { rating, assessment, correctionDocument: correctionDocumentIri }, {});

    return attempt;
  },
  approveAttempt: async (attemptId: string) => {
    const { data } = await axios.put<Attempt | HomeworkAttempt>(`admin/attempts/${attemptId}/approve`, {});

    return data;
  },
  refuseAttempt: async (attemptId: string) => {
    const { data } = await axios.put<Attempt | HomeworkAttempt>(`admin/attempts/${attemptId}/refuse`, {});

    return data;
  },
  assignCorrector: async (attemptId: string, correctorId?: string): Promise<Attempt> => {
    const { data } = await axios.put<Attempt>(`/admin/attempts/${attemptId}/corrector`, {
      corrector: correctorId,
    });

    return data;
  },
  unassignCorrector: async (attemptId: string): Promise<Attempt> => {
    const { data } = await axios.put<Attempt>(`/admin/attempts/${attemptId}/unassign`, {});

    return data;
  },
  updateAnswerRating: async (userAnswerId: string, points: number | null): Promise<UserAnswer> => {
    const { data } = await axios.put<UserAnswer>(`/admin/user_answers/${userAnswerId}`, {
      points,
    });

    return data;
  },
};

export default attemptService;
