import axios from "axios";
import { Training } from "../../types/training";
import { APIPlatformList } from "../../types/utils";

const trainingService = {
  getTraining: async (trainingSlug: string): Promise<Training> => {
    const { data } = await axios.get<Training>(`/trainings/${trainingSlug}`);

    return data;
  },
  getEnroledTraining: async (): Promise<Training[]> => {
    const {
      data: { "hydra:member": trainings },
    } = await axios.get<APIPlatformList<Training>>("/admin/users/trainings");

    return trainings;
  },
};

export default trainingService;
