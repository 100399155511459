<template>
  <div class="flex h-screen">
    <aside class="flex w-64 flex-col border-r border-gray-50 bg-white px-4 py-3">
      <img v-if="currentStructure" :src="currentStructure.logo?.['@image_formats'].avatar_medium" class="mb-4 aspect-square h-10 w-10 rounded object-cover" :alt="currentStructure.name" />

      <div>
        <div>
          <Listbox v-if="currentTraining" v-model="currentTraining" as="div">
            <div class="relative w-full max-w-sm">
              <ListboxButton class="text-primary-950 sm: relative w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:leading-6">
                <div class="flex-column flex items-center">
                  <img v-if="currentTraining?.favicon" :src="currentTraining.favicon?.['@image_formats'].avatar_medium" class="mr-2 aspect-square h-7 w-7 rounded object-cover" :alt="currentTraining?.name" />
                  <span class="block truncate"><!--<span class="text-slate-300">Sélectionner une formation</span>-->{{ currentTraining.name }}</span>
                </div>
                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </ListboxButton>
              <ListboxOptions class="sm: absolute z-40 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none">
                <ListboxOption v-for="training in trainings" v-slot="{ active, selected, disabled }" :key="training.id" :value="training" :disabled="!training.userIsEnrolled">
                  <li v-if="disabled" :class="['text-gray-200', 'relative select-none py-2 pl-3 pr-9']">
                    <div class="flex-column flex items-center">
                      <img v-if="training.favicon" :src="training.favicon?.['@image_formats'].avatar_medium" class="mr-2 aspect-square h-7 w-7 rounded object-cover" :alt="training.name" />
                      <span :class="['font-normal', 'block truncate']" :title="training.name">{{ training.name }}</span>
                    </div>
                  </li>
                  <li v-else :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-pointer select-none py-2 pl-3 pr-9']" @click="router.push({ ...route, params: { ...route.params, trainingSlug: training.slug } })">
                    <div class="flex-column flex items-center">
                      <img v-if="training.favicon" :src="training.favicon?.['@image_formats'].avatar_medium" class="mr-2 aspect-square h-7 w-7 rounded object-cover" :alt="training.name" />
                      <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']" :title="training.name">{{ training.name }}</span>
                    </div>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </div>
          </Listbox>
        </div>
        <div class="mt-4">
          <router-link v-slot="{ isActive }" :to="{ name: 'calendar' }">
            <div :class="['flex-column flex aspect-square h-10 w-full place-items-center rounded-md px-3', isActive ? 'bg-primary-50 text-primary-600' : 'text-slate-700  hover:bg-slate-50']">
              <CalendarIcon class="h-4 w-4" /> <span class="ms-2">Agenda</span>
            </div>
          </router-link>
        </div>
        <div class="mt-1">
          <router-link v-slot="{ isActive }" :to="{ name: 'exams-attempts' }">
            <div :class="['flex-column flex aspect-square h-10 w-full place-items-center rounded-md px-3', isActive ? 'bg-primary-50 text-primary-600' : 'text-slate-700 hover:bg-slate-50']">
              <PuzzlePieceIcon class="h-4 w-4" /> <span class="ms-2">Correction tentatives</span>
            </div>
          </router-link>
        </div>
        <div class="mt-1">
          <router-link v-slot="{ isActive }" :to="{ name: 'suggestions' }">
            <div :class="['flex-column flex aspect-square h-10 w-full place-items-center rounded-md px-3', isActive ? 'bg-primary-50 text-primary-600' : 'text-slate-700 hover:bg-slate-50']">
              <UserCircleIcon class="h-4 w-4" /> <span class="ms-2">Suggestions</span>
            </div>
          </router-link>
        </div>
      </div>
    </aside>

    <div class="flex flex-1 flex-col">
      <header class="flex h-16 shrink-0 items-center justify-end border-b border-gray-50 bg-white px-6">
        <Menu v-if="currentUser" as="div" class="relative ml-3">
          <div>
            <MenuButton class="flex max-w-xs items-center rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <span class="sr-only">Open user menu</span>
              <div class="flex items-center">
                <div>
                  <img class="inline-block h-10 w-10 rounded-full object-cover" height="40" width="40" :src="currentUser.avatar?.['@image_formats']?.['avatar_small']" :alt="`Avatar de ${currentUser.username}`" />
                </div>
                <div class="ml-3 text-start">
                  <p class="font-medium text-gray-700 group-hover:text-gray-900">
                    {{ currentUser.username }}
                  </p>
                  <p class="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                    {{ currentUser.email }}
                  </p>
                </div>
              </div>
            </MenuButton>
          </div>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems class="absolute right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none">
              <MenuItem v-slot="{ active }" class="cursor-pointer">
                <a :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2  text-gray-700']" @click="toFront()">Retour à la plateforme</a>
              </MenuItem>
            </MenuItems>
          </transition>
        </Menu>
      </header>

      <main class="flex-1 overflow-auto">
        <router-view />
      </main>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { trainingService } from "../services/api";
import notFoundRoute from "../helpers/notFoundRedirect";
import axios from "axios";
import { MutationTypes } from "../types/store";
import store from "../store";

export default defineComponent({
  beforeRouteEnter: async (to, from, next) => {
    const { trainingSlug } = to.params;
    try {
      const currentTraining = await trainingService.getTraining(trainingSlug as string);
      axios.defaults.headers["X-TRAINING"] = currentTraining.id;

      store.commit(MutationTypes.SET_CURRENT_TRAINING, currentTraining);

      next((vm) => ((vm as any).currentTraining = currentTraining));
    } catch (error) {
      next(notFoundRoute(to));
    }
  },
});
</script>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";
import { PuzzlePieceIcon, UserCircleIcon, CalendarIcon } from "@heroicons/vue/24/outline";

import { NavigationGuardNext, onBeforeRouteUpdate, RouteLocationNormalized, useRoute, useRouter } from "vue-router";
import { Training } from "../types/training";

import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/vue";

import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { ChevronUpDownIcon } from "@heroicons/vue/20/solid";

import { useCurrentUser } from "../hooks/useCurrentUser";
import { useCurrentStructure } from "../hooks/useCurrentStructure";

const router = useRouter();
const route = useRoute();

const currentUser = useCurrentUser();
const currentStructure = useCurrentStructure();

const currentTraining = ref<Training | null>(null);

const trainings = ref<Training[]>([]);

onBeforeRouteUpdate(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (from.params.trainingSlug !== to.params.trainingSlug) {
    const { trainingSlug } = to.params;
    try {
      currentTraining.value = await trainingService.getTraining(trainingSlug as string);
      axios.defaults.headers["X-TRAINING"] = currentTraining.value.id;

      store.commit(MutationTypes.SET_CURRENT_TRAINING, currentTraining);
      next();
    } catch (error) {
      next(notFoundRoute(to));
    }
  } else {
    next();
  }
});

onBeforeUnmount(() => {
  axios.defaults.headers["X-TRAINING"] = null;
});

onMounted(async () => {
  trainings.value = await trainingService.getEnroledTraining();
});

const toFront = () => {
  const { protocol } = window.location;

  window.location = (`${protocol}//${window.env.DOMAIN}/` + currentTraining.value?.slug) as any;
};

defineExpose({ currentTraining });
</script>


