import axios from "axios";
import { Enrolment } from "../../types/enrolment";
import { User, ROLE } from "../../types/user";
import { APIPlatformList } from "../../types/utils";

const userService = {
  authenticate: async (): Promise<User> => {
    const { data: user } = await axios.get<User>(`/authenticate`);

    return user;
  },
  getEnrolments: async (username: string, role?: ROLE[], limit = 5): Promise<Enrolment[]> => {
    const {
      data: { "hydra:member": users },
    } = await axios.get<APIPlatformList<Enrolment>>(`/admin/enrolments`, {
      params: {
        username,
        role,
        limit,
      },
    });

    return users;
  },
  getAdminEnrolments: (username: string, limit = 5): Promise<Enrolment[]> => {
    return userService.getEnrolments(username, [ROLE.ADMIN, ROLE.PROFESSOR, ROLE.SUPER_ADMIN, ROLE.MODERATOR, ROLE.YOUSCHOOL], limit);
  },
};

export default userService;
